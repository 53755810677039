<template lang="pug">
span.app-label(
  :style="{color: color}"
) 
  span.app-label__text {{ text }}
</template>

<script>
export default {
  props: {
    text: String,
    color: String
  }
}
</script>

<style lang="scss" scoped>
.app-label {
  display: inline-block;
  font-size: 8px;
  margin-right: 3px;
  font-weight: bold;
  padding: 2px;
  color: #fff;
  background-color: currentColor;
  border-radius: 2px;
  line-height: 1;
  &__text {
    color: #fff;
    line-height: 0;
  }
}
</style>